<template>
  <div>

    <Slideshow />

    <Projects />
    
    <Skills />
    
    
    
    <Workplaces />
    
  </div>
</template>

<script>
import Slideshow from "../partials/slideshow.vue";
import Skills from "../partials/skills.vue";
import Projects from "../partials/projects.vue";
import Workplaces from "../partials/workplaces.vue";


export default {
  name: "IntroPage",

  components: {
    Slideshow, Skills, Projects, Workplaces,

  },
};
</script>
