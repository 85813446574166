<template>


      <v-carousel
        cycle
        height="300"
        hide-delimiter-background
        show-arrows-on-hover
          
      >
        <v-carousel-item v-for="(slide, i) in slides" v-bind:key="i">
          <v-sheet :color="slide.color + ' darken-4'" height="100%"
            class="py-4"><v-row class="fill-height" align="center" justify="center">
              <v-col justify="center" align="center">
                <v-card max-width="400" class="mx-4">
                  <v-card-title
                    ><v-icon :color="slide.color + ' darken-2'" class="mr-2"
                      >mdi-{{ slide.icon }}</v-icon
                    >
                    {{ slide.title }}</v-card-title
                  >
                  <v-card-text align="left">{{ slide.text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>

</template>
<script>
export default {
  name: "SlideshowRow",
  data: () => ({
    interval: 6,
    slide: 0,

    slides: [
      {
        title: "About Me",
        text: "I’m a senior full stack web developer specialising in making applications using Vue.js front ends with AWS API backends deployed via the Serverless framework.",
        icon: "card-account-details",
        color: "purple",
      },
      {
        title: "My Skills",
        text: "I prefer Python for writing backends, but am also fluent in Node.js and PHP.  I keep my skills current and I’ve been teaching myself new frameworks, languages, and paradigms from a young age.",
        icon: "hammer-screwdriver",
        color: "cyan",
      },
      {
        title: "Team Leadership",
        text: "I have a lot of experience managing teams of developers; I really enjoy helping colleagues build their skills and confidence through coaching and training.",
        icon: "account-group",
        color: "blue",
      },
      // {
      //   title: "Clients",
      //   text: "I have been there for many clients over the years, always finding the best way to get the job done exceptionally and on time.",
      //   icon: "shield-star",
      //   color: "blue",
      // },
      // {
      //   title: "Learning Is My Job",
      //   text: "Every problem is a challenge with something to learn from and I have been teaching myself new frameworks, languages, and paradigms since a very young age",
      //   icon: "bookshelf",
      //   color: "brown",
      // },
      {
        title: "Experience",
        text: "I’ve worked with a variety of clients, from industry heavyweights to small passion projects; this wealth of experience helps me find the best solutions.",
        icon: "sun-clock",
        color: "yellow",
      },
      {
        title: "Architecture",
        text: "I have worked with both NoSQL and SQL databases, architectured infrastructure, and setup CI/CD pipelines.",
        icon: "office-building-plus",
        color: "grey",
      },
      {
        title: "Put The User First",
        text: "My eye for detail and focus on user experience means the content I create is clear, clean, and logical, and I never cut corners with my code. ",
        icon: "account-heart",
        color: "red",
      },
      {
        title: "I Love It When",
        text: "I get to help people grow ethical and positive organisations; I’m currently working for WT.Social, getting the world in better shape online.",
        icon: "heart",
        color: "green",
      }
    ],
  }),
};
</script>
