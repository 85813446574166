<template>
  <v-sheet color="grey" class="grey-linear-gradient  pb-8">
    <v-container>
      <v-row>
        <v-col cols="12" class="grey--text text--darken-1 text-center my-4">
          <h2>My Skills and Abilities</h2>
        </v-col>
      </v-row>
      <template v-for="(skillset, set) in skills">
        <div v-bind:key="set" align="center" justify="center">
          
          <h3 class="grey--text text--lighten-1 my-8 skillset">
            <v-icon color="grey lighten-1">{{ skillset.icon }}</v-icon>  {{ skillset.label }}
          </h3>
          <v-row align="center" justify="center">
            <v-col cols="4" sm="3" md="2" v-for="skill in skillset.skills" :key="skill.label">
              <v-icon large :color="skill.color" align="center" class="mr-2">{{
                skill.icon
              }}</v-icon><br/>
              <span class="skilllabel grey--text text--darken-1">{{ skill.label }}</span>
            </v-col>
          </v-row>
          <v-divider class="my-4" v-if="set != lastSkillSet"/>
        </div>
      </template>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "SkillsRow",
  data: () => ({
    skills: {
      frontend: {
        label: "Front End",
        icon: "mdi-palette",
        color: "cyan",
        skills: [
          {
            label: "Vue.js, Vuetify, Vuex, Vue Router",
            icon: "mdi-vuejs",
            color: "green",
          },
          {
            label: "Javascript",
            icon: "mdi-language-javascript",
            color: "green",
          },
          {
            label: "CSS/SCSS",
            icon: "$vuetify.icons.scss",
            color: "green",
          },
          {
            label: "HTML",
            icon: "$vuetify.icons.html",
            color: "green",
          },
          {
            label: "Photoshop & Illustrator",
            icon: "$vuetify.icons.adobe",
            color: "black",
          },
        ],
      },
      backend: {
        label: "Back End",
        icon: "mdi-flash",
        color: "cyan",
        skills: [
          {
            label: "Python",
            icon: "$vuetify.icons.python",
            color: "blue",
          },
          {
            label: "Node.js",
            icon: "$vuetify.icons.nodejs",
            color: "blue",
          },
          {
            label: "PHP",
            icon: "$vuetify.icons.php",
            color: "blue",
          },
          {
            label: "AWS DynamoDB",
            icon: "$vuetify.icons.dynamodb",
            color: "blue",
          },
          {
            label: "AWS Lambda",
            icon: "$vuetify.icons.lambda",
            color: "blue",
          },
          {
            label: "MySQL",
            icon: "$vuetify.icons.mysql",
            color: "blue",
          },
        ],
      },
      infrastructure: {
        label: "Infrastructure",
        icon: "mdi-office-building-plus",
        color: "cyan",
        skills: [
          {
            label: "Amazon Web Services",
            icon: "$vuetify.icons.aws",
            color: "grey darken-3",
          },
          {
            label: "Serverless",
            icon: "$vuetify.icons.serverless",
            color: "grey darken-3",
          },
          {
            label: "Gitlab CI/CD",
            icon: "$vuetify.icons.gitlab",
            color: "grey darken-3",
          },
          {
            label: "Linux",
            icon: "$vuetify.icons.linux",
            color: "grey darken-3",
          },
          {
            label: "Docker",
            icon: "$vuetify.icons.docker",
            color: "grey darken-3",
          },
          {
            label: "Redis",
            icon: "$vuetify.icons.redis",
            color: "grey darken-3",
          },
        ],
      },
      legacy: {
        label: "Legacy Tools",
        icon: "mdi-hammer-wrench",
        color: "cyan",
        skills: [
          {
            label: "Laravel",
            icon: "mdi-laravel",
            color: "grey",
          },
          {
            label: "Code Igniter",
            icon: "mdi-fire",
            color: "grey",
          },
          {
            label: "WordPress",
            icon: "mdi-wordpress",
            color: "grey",
          },
          {
            label: "Drupal",
            icon: "mdi-drupal",
            color: "grey",
          },
        ],
      },
    },
  }),
  computed: {
    lastSkillSet() {

      return [Object.keys(this.skills)[Object.keys(this.skills).length - 1]]
    }
  }
};
</script>
<style lang="scss" scoped>

</style>