import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


import icons from '../components/partials/icons.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            
            adobe: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'adobe',
                },
            },
            aws: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'aws',
                },
            },
            docker: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'docker',
                },
            },
            dynamodb: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'dynamodb',
                },
            },
            html: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'html',
                },
            },
            gitlab: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'gitlab',
                },
            },
            lambda: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'lambda',
                },
            },
            linux: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'linux',
                },
            },
            mysql: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'mysql',
                },
            },
            nodejs: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'nodejs',
                },
            },
            python: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'python',
                },
            },
            php: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'php',
                },
            },
            redis: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'redis',
                },
            },
            serverless: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'serverless',
                },
            },
            scss: {
                component: icons, // you can use string here if component is registered globally
                props: { // pass props to your component if needed
                    icon: 'scss',
                },
            },
        },
    }
});
