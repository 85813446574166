<template>
  <v-sheet color="blue-radial-gradient" dark class="mt-n3 pt-4 pb-8">
    <v-container>
      <v-row>
        <v-col cols="12" class="white--text text-center my-4">
          <h2>Active and Recent Projects</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" class="white--text" v-for="(project, p) in projects" v-bind:key="p">
          
          <v-card>
            
              <v-card-title>
              {{ project.name }} <a target="_blank" v-if="project.link" :href="project.link" class="pl-2"><v-icon>mdi-link</v-icon></a>
            </v-card-title>
              <v-img :src="require('@/assets/images/screenshots/'+project.image)"/>
              <v-card-text>
              <p v-html="project.text"></p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "ProjectRow",
  data: () => ({
    projects: [
      {
        name: "Quiz Night Beyond",        
        text: "In between working on Wikitribune and WT.social Jimmy asked me to build a website that allows users to create interactive quizzes and host online games nights with friends and family via browser-based video and chat. ",
        image: "quiznightbeyond.png",
        link: "https://quiznightbeyond.com/"
      },
      {
        name: "WT.social (V1 & V2)",        
        text: "Wikitribune soon evolved into WT.social, and I continued to work with Jimmy to develop the original concept into a social network focused on preventing the spread of misinformation through community corrections and verification of content; I’m currently working on the second iteration of this.",
        image: "wts2.png",
        link: "https://wts2.wt.social/"
      },
      {
        name: "Wikitribune",        
        text: "I’ve been working with web trailblazer Jimmy Wales for five years now; Wikitribune was the first project I worked with Jimmy on. Wikitribune was designed to allow community members to work alongside journalists to publish accurate and verified hard news items via a wiki-style app. As one of a small, agile group of developers, I got to work as part of an amazing team, collaborating closely with Jimmy, our journalists, and our community members. ",
        image: "wikitribune.png"
      },
    ]
  }),
};
</script>
