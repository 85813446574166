<template>
<v-sheet color="grey lighten-4">

<v-container>
  <v-row id="header" >
    <v-col align="center" justify="center">
      <p class="logo mb-1">Simon Little</p>
      <p class="subtitle mt-n5 grey--text mb-0">Full Stack Developer</p>
    </v-col>
    
  </v-row>

  </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "HeaderPart",
  data: () => ({
        
    })
};
</script>
