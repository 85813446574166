<template>
  <v-sheet color="blue" class="skyblue-linear-gradient" light>
    <v-container>
      <v-row>
        <v-col cols="12" class="white--text text-center my-4">
          <h2>Key Stages Of My Career</h2>
        </v-col>
        <v-col>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item v-for="(place, i) in workplaces" v-bind:key="i" :icon="'mdi-'+place.icon" fill-dot color="blue darken-4" >
              <span slot="opposite" class="white--text">{{ place.timing }}</span>
        
              <v-card color="white">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ place.name }} <a target="_blank" v-if="place.link" :href="place.link"><v-icon>mdi-link</v-icon></a>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ place.sub }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text>
                  <strong class="hidden-md-and-up">{{ place.timing }}<br/></strong>
                  {{ place.text }}
                </v-card-text>
                  
              </v-card>

            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "WorkPlacesRow",
  data: () => ({
    workplaces: [
      {
        name: "Wikitribune",
        timing: "2017-present",
        sub: "Wikitribune, WT.Social, Quiz Night Beyond",
        text: "Since 2017 I have been working with Wikipedia founder, Jimmy Wales, on a range of projects with the key mission of fighting misinformation online, with some fun segues into video call-based gaming. Working as the senior Developer in the team I also managed the development team, setting up deployment pipelines and organising the ticketing boards.",
        icon: "shield-star"
      },
      {
        name: "Philosophy Design",
        timing: "2014-2017",
        sub: "WFW, MSI, Stace, McLaren",
        text: "I was the senior developer at Philosophy Design, a B2B agency specialising in branding. I led a small team, took responsibility for representing the company in all development matters, and oversaw the hosting, email, and mailout client offer for Philosophy.",
        icon: "lightbulb-group",
        link: "https://philosophydesign.com/"
      },
      {
        name: "The Blue Light District",
        timing: "2009-2014",
        sub: "WMW, The Constitution Society, Flubit, iWahh",
        text: "I leveraged a big project into the opportunity to launch my own agency, which was both a huge amount of fun and incredibly educational. Working with a small team I led all the front-end and back-end development work. I worked with an eclectic range of clients ranging from a proto-social media chart site (with background processing of huge files), rock bands, and even lawyers working on an advocacy project for political education. ",
        icon: "handshake"
      },
      {
        name: "Thirty Three",
        timing: "2007-2009",
        sub: "BT, RBS, Oxfam, Barclays",
        text: "My first developer role was at Thirty Three. The first thing they asked me to do was remake their own website from scratch. There was a lot of work using Flash as it was popular at the time. The bulk of my work was on custom job application sites with a tracking system to guide companies through the process of hiring without using a recruiter. Clients included BT, Oxfam, RBS, and Barclays, among others.",
        icon: "star",
        link: "https://www.thirtythr.ee/uk"
      },
      {
        name: "Background",
        timing: "2002-2007",
        sub: "Self-taught",
        text: "I’d always experimented with making websites for fun, but my dev career really began when a colleague lent me a book on PHP and MySQL, which helped me take my hobby to a professional level. I used to make QBasic text-based adventure games as a kid, so learning PHP was like riding a bike! To hone my skills I made websites for anyone that needed one (friends and family definitely benefited from this), then transitioned into my first professional dev role.",
        icon: "map-marker-star"
      },
    ],
  }),
};
</script>
