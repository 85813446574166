<template>
<v-sheet color="white">

<v-container>
  <v-row id="footer" align="center" justify="center" >
   
    <v-col cols="2" md="1"  v-for="(link, l) in linklist"
        v-bind:key="l"
      >
        <a
          :href="link['url']"
          target="_blank"
          :title="link['label']"
          @mouseover="hover = l"
          @mouseleave="hover = null"
        >
          <v-icon large :color="'grey ' + [hover == l ? 'darken-2' : '']"
            >mdi-{{ link["icon"] }} {{ hover }}</v-icon
          >
        </a>
    </v-col>
  </v-row>

  </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "HeaderPart",
  data: () => ({
        testVal: "dsasdsa",
        hover: null,
        linklist: [
            {
                label: "GitLab",
                url: "https://gitlab.com/simonlittle",
                icon: "gitlab",
            },
            {
                label: "GitHub",
                url: "https://github.com/bldcaveman",
                icon: "github",
            },
            {
                label: "Stack Overflow",
                url: "https://stackoverflow.com/users/3110658/bldcaveman",
                icon: "stack-overflow",
            },
            {
                label: "LinkedIn",
                url: "https://www.linkedin.com/in/simon-little-3bb47526/",
                icon: "linkedin",
            },
            {
                label: "mail",
                url: "mailto: hello@simonlittle.online",
                icon: "email",
            }
            
        //     {
        //         label: "mail",
        //         url: "mailto: simon@glasselevatorstudios.com",
        //         icon: "email",
        //     },
        ],
    })
};
</script>
