<template>
  <v-app>

    <v-main class="mainbody">
      <Header/>
      <router-view />
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/partials/header.vue';
import Footer from './components/partials/footer.vue';

export default {
  name: 'App',

  components: {
    Header,
    Footer
},

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
  @import '@/assets/scss/main';
  </style>