import Vue from 'vue'
import Router from 'vue-router'
import Intro from './../components/views/Intro.vue'

Vue.use(Router)

export default new Router({
    routes: [
      { path: '/', component: Intro },
    ]
})
