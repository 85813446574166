<template>
  <div v-if="icon">
    <adobeLogo
      v-if="icon == 'adobe'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <awsLogo
      v-else-if="icon == 'aws'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <dockerLogo
      v-else-if="icon == 'docker'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <dynamodbLogo
      v-else-if="icon == 'dynamodb'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <html5Logo
      v-else-if="icon == 'html'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <gitlabLogo
      v-else-if="icon == 'gitlab'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <linuxLogo
      v-else-if="icon == 'linux'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <lambdaLogo
      v-else-if="icon == 'lambda'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <mysqlLogo
      v-else-if="icon == 'mysql'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <nodejsLogo
      v-else-if="icon == 'nodejs'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <phpLogo
      v-else-if="icon == 'php'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <pythonLogo
      v-else-if="icon == 'python'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <qnbLogo
      v-else-if="icon == 'qnb'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <redisLogo
      v-else-if="icon == 'redis'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
      />
    <serverlessLogo
      v-else-if="icon == 'serverless'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
    <scssLogo
      v-else-if="icon == 'scss'"
      :width="defaultWidth"
      :height="defaultHeight"
      :class="defaultClass"
    />
  </div>
</template>
<script>
import adobeLogo from "../../assets/images/svgicons/adobe-svgrepo-com.svg?inline";
import awsLogo from "../../assets/images/svgicons/aws-orange-svgrepo-com.svg?inline";
import dockerLogo from "../../assets/images/svgicons/docker-svgrepo-com.svg?inline";
import dynamodbLogo from "../../assets/images/svgicons/aws-dynamodb-svgrepo-com.svg?inline";
import html5Logo from "../../assets/images/svgicons/html-svgrepo-com.svg?inline";
import gitlabLogo from "../../assets/images/svgicons/gitlab-svgrepo-com.svg?inline";
import lambdaLogo from "../../assets/images/svgicons/aws-lambda-svgrepo-com.svg?inline";
import linuxLogo from "../../assets/images/svgicons/linux-svgrepo-com.svg?inline";
import mysqlLogo from "../../assets/images/svgicons/mysql-logo-svgrepo-com.svg?inline";
import nodejsLogo from "../../assets/images/svgicons/nodejs-icon-svgrepo-com.svg?inline";
import phpLogo from "../../assets/images/svgicons/php-svgrepo-com.svg?inline";
import pythonLogo from "../../assets/images/svgicons/python-svgrepo-com.svg?inline";
import qnbLogo from "@/assets/images/svgicons/quiznightsvg.svg?inline";
import redisLogo from "../../assets/images/svgicons/redis-svgrepo-com.svg?inline";
import scssLogo from "../../assets/images/svgicons/scss-svgrepo-com.svg?inline";
import serverlessLogo from "../../assets/images/svgicons/serverless-svgrepo-com.svg?inline";

export default {
  name: "SVGIcons",
  data: () => ({
    defaultWidth: "100%",
    defaultHeight: "auto",
    defaultClass: "svg-icon icon",
  }),
  props: ["icon"],
  components: {
    adobeLogo,
    awsLogo,
    dockerLogo,
    dynamodbLogo,
    html5Logo,
    gitlabLogo,
    lambdaLogo,
    linuxLogo,
    mysqlLogo,
    nodejsLogo,
    phpLogo,
    pythonLogo,
    qnbLogo,
    redisLogo,
    scssLogo,
    serverlessLogo,
  },
  mounted() {
    if (this.icons) {
      console.log(this.icon);
    }
  },
};
</script>
